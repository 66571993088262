@import url("../style/variables.css");

.site-header a {
  text-decoration: none;
  color: var(--black-4-color);
}
.site-header a:active {
  color: var(--black-5-color);
}
.site-header a:focus {
  color: var(--black-5-color);
}
.site-header {
  border-bottom: 1px solid var(--grey-color);
  padding: 0.1em 1em;
  display: flex;
  justify-content: space-between;
}

.site-identity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-navigation ul,
.site-navigation li {
  margin: 0;
  padding: 0;
}

.site-navigation li {
  display: inline-block;
  margin: 1.4em 1em 1em 1em;
}
