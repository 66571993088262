@import url("./variables.css");

.login-container {
  display: flex;
  height: 100vh;
}

.login-container .left {
  display: absolute;
  width: 50%;
  height: 100%;
}

.login-container img {
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 50%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.login-container .right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  background-color: var(--default-bakground);
  padding: 2rem 2rem;
}
.login-container .right-side .text-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
}
.login-container .right-side .text-wrapper h3 {
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.login-container .right-side .text-wrapper p {
  margin: 0;
  padding: 0;
  color: var(--black-5-color);
  width: 400px;
}
.login-container .login-form {
  background-color: white;
  padding: 43px 37px;
  border-radius: 20px;
  border: 1px solid var(--grey-color);
  width: 400px;
}
.login-container .login-form h2 {
  font-size: var(--font-24);
  color: var(--black-5-color);
  text-align: left;
  margin-bottom: 20px;
  font-weight: normal;
}

.login-container .btn {
  width: 100%;
  padding: 10px;
  height: 58px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
}
.login-container .btn:hover {
  color: white;
  background-color: var(--primary-hover-color);
}

.login-container button:disabled,
.login-container button:disabled:hover {
  cursor: not-allowed;
  background-color: var(--primary-hover-color);
}

.login-container .left h1 {
  font-size: 48px;
  margin: 0;
}
.login-container .left p {
  font-size: 24px;
  margin: 10px 0 0;
}
