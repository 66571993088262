@import url("../style/variables.css");

.projectlist-container {
  padding: var(--default-screen-padding);
  background-color: var(--default-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow);
}

.projectlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectlist-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.projectlist-sync-button,
.projectlist-log-button {
  display: inline-block;
  padding: 0.5em 1em;
  color: var(--black-5-color);
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 1px solid var(--grey-color);
  cursor: pointer;
}

.projectlist-sync-button.projectlist-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.projectlist-sync-button:hover,
.projectlist-log-button:hover {
  background-color: var(--table-row-bg);
  transform: scale(1.05);
}

.projectlist-sync-button:focus,
.projectlist-log-button:focus {
  outline: none;
}

.projectlist-divider {
  border: 0;
  height: 1px;
  background-color: var(--grey-color);
  margin-bottom: 2em;
}

.projectlist-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}
