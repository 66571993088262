@import url("../style/variables.css");

.changePass {
  padding: var(--default-screen-padding);
  background-color: var(--default-background);
}
.changePass-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.changePass-card {
  align-items: center;
  padding: 0.75em 2em 2em 2em;
  max-width: 60vw;
  min-width: 50vw;
  border: 1px solid var(--grey-color);
  border-radius: 8px;
  overflow: hidden;
}
.changePass h2 {
  font-size: var(--font-24);
  color: var(--black-5-color);
  text-align: left;
  margin-bottom: 20px;
  font-weight: normal;
}

.changePass .changePass-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.changePass .changePass-btn {
  width: 25%;
  padding: 10px;
  height: 58px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
}

.changePass .changePass-btn:hover {
  color: white;
  background-color: var(--primary-hover-color);
}
