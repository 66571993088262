:root {
  --primary-color: #38b6ff;
  /* --primary-color: #5570f1; */
  --primary-hover-color: #5479f7;
  /* --primary-hover-color: #0056b3; */
  --success: #14a44d;
  --danger: #dc4c64;
  --danger-color: #f57e77;
  --grey-color: #dde2e5;
  --green-color: #32936f;
  --black-color: #121314;
  --black-5-color: #2b2f32;
  --black-4-color: #5e6366;
  --black-2-color: #abafb1;
  --default-bakground: #eff1f9;
  --table-strip-backgroud: #f2f2f2;
  --box-shadow: rgba(0, 0, 0, 0.1);
  --font-24: 24px;
  --default-screen-padding: 3em 3em;
  --table-text-color: #636363;
  --table-row-bg: #edf2fe;
}
