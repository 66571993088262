@import url("../style/variables.css");

.project-list {
  padding: var(--default-screen-padding);
  background-color: var(--default-background);
}

.card {
  border: 1px solid var(--grey-color);
  border-radius: 8px;
  overflow: hidden;
}

.card-body {
  overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.table th {
  padding: 0.75em;
  text-align: left;
  border-bottom: 1px solid var(--grey-color);
}

.table td {
  padding: 0.75em;
  text-align: left;
  border-bottom: 1px solid var(--grey-color);
  color: var(--table-text-color);
}

.table th {
  background-color: var(--table-row-bg);
  color: var(--black-5-color);
}

.project-list tr:hover {
  background-color: var(--table-row-bg);
  cursor: pointer;
}

.project-list a {
  text-decoration: none;
  color: inherit;
}
.project-list a:hover {
  text-decoration: underline;
  color: inherit;
}
/* .table-striped tbody tr:nth-of-type(even) {
  background-color: var(--table-strip-backgroud);
} */

.btn-link {
  display: inline-block;
  padding: 0.5em 1em;
  color: white;
  background-color: var(--green-color);
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-link:hover {
  background-color: var(--primary-hover-color);
}

.btn-link i {
  margin-right: 0.5em;
}
