@import url("../style/variables.css");

.credential-page {
  padding: var(--default-screen-padding);
  background-color: var(--default-background);
}

.credential-page .card-body {
  padding: 0em 2em 2em 2em;
}

.credential-page .update-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.credential-page .update-btn {
  width: 25%;
  padding: 10px;
  height: 58px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
}

.credential-page .update-btn:hover {
  color: white;
  background-color: var(--primary-hover-color);
}
