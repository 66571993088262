body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
  color: inherit;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-100-vh {
  width: 100vw;
}
.h-100-vh {
  height: 100vh;
}
.bg-whatsapp {
  background-color: #128c7e;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group .input-group {
  display: flex;
  align-items: center;
}
.input-group-append {
  position: absolute;
  right: 20px;
  color: var(--black-5-color);
  cursor: pointer;
}
.form-group input,
.form-group select {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  padding-top: 12px;
  border: 1px solid var(--grey-color);
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 16px;
  background-color: transparent;
}
.form-group input::placeholder {
  font-size: 16px;
  color: var(--black-2-color);
}

.form-group.error input,
.form-group.error select,
.form-group.error input:focus,
.form-group.error select:focus {
  border-color: var(--danger-color);
}
.error-line {
  color: var(--danger-color);
  font-size: 16px;
}

.form-group input:focus,
.form-group select:focus {
  border-color: var(--primary-color);
  outline: none;
}

.form-group input:disabled,
.form-group select:disabled {
  cursor: not-allowed;
}

.form-group label {
  position: absolute;
  top: 5px;
  left: 16px;
  font-size: 14px;
  color: var(--black-4-color);
  pointer-events: none;
  font-weight: 300;
}
.info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.info i {
  font-size: 18px;
}

.badge-danger {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #ffebeb;
}
.badge-success {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #e4ffe5;
}

.hover-text-underline:hover {
  text-decoration: underline;
}
.display-flex {
  display: flex;
}
.half-div {
  width: 50%;
}

h1 {
  font-size: 2.125rem;
}
h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

.font-weight-300 {
  font-weight: 300;
}
.text-medium {
  font-size: 16px;
}
.text-big {
  font-size: 20px;
}
