@import url("../style/variables.css");

.log-container {
  padding: var(--default-screen-padding);
  background-color: var(--default-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow);
}

.dowload-csv-btn {
  display: inline-block;
  padding: 0.5em 1em;
  color: var(--black-5-color);
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 1px solid var(--grey-color);
  cursor: pointer;
}

.dowload-csv-btn:hover {
  background-color: var(--table-row-bg);
  transform: scale(1.05);
}
